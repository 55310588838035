import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';

import Layout from '@/components/Layout';

import { contactMail, phoneNb } from '@/referentials/infos';

const linkClasses =
  'underline underline-offset-2 hover:text-primary transition-colors duration-500';

export default function NotFound() {
  const { t } = useTranslation();

  const phone = useMemo(
    () =>
      phoneNb
        .split('')
        .reduce(
          (acc, n, i) =>
            i !== phoneNb.length - 1 && i % 2 ? `${acc}${n}.` : `${acc}${n}`,
          '',
        ),
    [phoneNb],
  );

  return (
    <Layout description="Contact">
      <div className="mt-[150px] lg:mt-[200px]">
        <div className="font-heading leading-normal text-4xl lg:text-[60px]">
          {t('title')}
        </div>
        <div className="mt-6 subcontent lg:w-2/3">{t('subTitle')}</div>
        <div className="font-heading mt-12 lg:mt-[80px] text-2xl lg:text-5xl">
          <div className="mb-[33px] lg:mb-[66px]">
            <div className="w-[50px] lg:w-[90px] inline-block">📞</div>
            <Trans i18nKey="callMsg">
              <a
                className={linkClasses}
                href={`tel:+33${phoneNb.substring(1)}`}
              >
                {{ phone }}
              </a>
            </Trans>
          </div>
          <div>
            <div className="w-[50px] lg:w-[90px] inline-block">📧</div>
            <Trans i18nKey="emailMsg">
              <a className={linkClasses} href={`mailto:${contactMail}`}>
                {{ contactMail }}
              </a>
            </Trans>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["contact", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
